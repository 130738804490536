.headerBox {
    display: flex;
    gap: 8px;
    margin: 32px;
    width: 308px;
}

@media screen and (max-width: 595px) {
    .headerBox {
        margin: 32px auto 0;
    }
}
