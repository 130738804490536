.successedEmail {
  display: flex;
  flex-direction: column;
  background-image: url('../../images/mail_illustration.png');
  background-repeat: no-repeat;
  background-position: 50% 20%;
  background-size: 110px 120px;
  box-sizing: border-box;
  width: 434px;
}

.letter {
  width: 110px;
  height: 125px;
  margin: 40px auto 0;
}

.successedEmailSpan {
  width: 252px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0;
  align-self: stretch;
  color: #584a75;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

@media screen and (max-width: 595px) {
  .letter {
    margin: 30px auto 0;
  }
  .successedEmail {
    max-width: 284px;
  }
}
