.mainPageContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 88px);
}

.mainPage {
  display: flex;
  justify-content: center;
}

.headerBox {
  max-width: 465px;
  max-height: 508px;
  margin: 50px 163px 24px 0;
}

.header {
  margin: 0;
  margin-bottom: 24px;
  color: #302f61;
}

.header,
.sqdfy {
  font-size: 128px;
  font-style: normal;
  font-weight: 900;
  line-height: 105px;
  text-transform: uppercase;
}

@keyframes shimmeringEffect {
  to {
    background-position: -200% center;
  }
}

.sqdfy {
  background: radial-gradient(
    circle at 100%,
    #5df6ff 8.98%,
    #7ba0ff 32.87%,
    #b072ff 60.85%,
    #ffca7b 85.6%,
    #5df6ff 100%
  );
  background-size: 200% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shimmeringEffect 7s infinite linear;
}

.description {
  margin: 0;
  color: #584a75;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  max-width: 393px;

  span {
    font-weight: 700;
  }
}

.box {
  margin-top: 75px;
  height: 704px;
  min-width: 466px;
  border-radius: 62px 62px 0 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  border-top: 8px solid white;
  border-left: 15px solid white;
  border-right: 15px solid white;
  background-color: #ffffff;
}

.circles {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  z-index: -1;
}

.circle {
  width: 322px;
  height: 322px;
  filter: blur(130px);
  transform: translate3d(0, 0, 0);
}

.circle:nth-child(1) {
  margin-right: -80px;
  margin-top: 80px;
  background-color: #ffc659;
}

.circle:nth-child(2) {
  background-color: #5bf5ff;
  margin-top: -100px;
}

.circle:nth-child(3) {
  margin-top: 80px;
  margin-left: -100px;
  background-color: #b072ff;
}

.formBox {
  display: flex;
  flex-direction: column;
  padding: 70px 0 70px;
  background: url('../../images/form_background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  h3 {
    margin: 12px 0 8px;
    color: #1a1426;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
  }
}

.formBoxSpan {
  margin: 0 auto 29px;
  align-self: stretch;
  color: #584a75;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.form {
  display: flex;
  flex-direction: column;
  width: 344px;
  margin: 0 auto;

  button {
    cursor: pointer;
    border: none;
    height: 56px;
    padding: 12px 24px;
    border-radius: 16px;
    background: #1a1426;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

.atPlace {
  position: relative;
  display: flex;
}

.inputEmail {
  display: block;
  width: 100%;
  border: 1px solid #e3ddee;
  padding: 20px 16px;
  border-radius: 16px;
  padding-left: 40px;
  outline: none;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.inputEmail::placeholder,
.inputError::placeholder {
  color: #aaa2ba;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.inputEmail:focus {
  border: 1px solid rgba(170, 162, 186, 1);
  caret-color: rgba(176, 114, 255, 1);
}

.atPlace {
  position: relative;
}

.atPlace::before {
  content: '';
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.at::before {
  width: 17px;
  height: 17px;
  box-sizing: border-box;
  background-image: url('../../images/At.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.caption {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
  color: rgba(170, 162, 186, 1);
  margin: 10px 0 0 0;

  span {
    font-weight: 700;
  }
}

.errorText {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(252, 54, 125, 1);
  margin: 4px 0 10px;
}

.inputError {
  border: 1px solid rgba(252, 54, 125, 1);
  border-radius: 16px;
  display: block;
  width: 100%;
  padding: 20px 16px;
  border-radius: 16px;
  padding-left: 40px;
  outline: none;
  color: rgba(252, 54, 125, 1);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.erorrPlace {
  margin-bottom: 32px;
}

@media screen and (max-width: 1298px) {
  .box {
    margin-right: 30px;
  }
  .headerBox {
    margin: 136px 30px 24px 30px;
  }
  .header,
  .sqdfy {
    font-size: 90px;
    line-height: 70px;
  }
}

@media screen and (max-width: 970px) {
  .box {
    border-top: 14px solid white;
  }
  .mainPageContainer {
    align-items: flex-start;
  }
  .mainPage {
    flex-direction: column;
  }
  .headerBox {
    margin: 43px auto 0;
  }

  .header {
    margin-bottom: 14px;
  }

  .sqdfy,
  .header {
    font-size: 78px;
    line-height: 64px;
  }
  .description {
    font-size: 18px;
    line-height: 24px;
    width: 350px;
  }
  .box {
    margin: 24px auto 0;
  }
  .circle {
    width: 294px;
    height: 294px;
  }

  .formBox {
    background: url('../../images/Frame.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    align-items: center;

    h3 {
      font-size: 32px;
    }
  }
  .formBoxSpan {
    font-size: 18px;
    margin: 0 0 24px;
  }

  .form {
    width: 300px;
  }

  .caption {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 912px) and (min-width: 596px) {
  .box {
    height: 704px;
  }
}

@media screen and (max-width: 725px) {
  .headerBox {
    margin: 43px auto 0;
  }
}

@media screen and (max-width: 595px) {
  .box {
    height: 550px;
    border-radius: 50px 50px 0;
  }
  .description {
    width: 250px;
    font-size: 14px;
    line-height: 22px;
  }
  .circles {
    left: 0;
    transform: none;
  }

  .circle {
    filter: blur(60px);
    position: absolute;
  }
  .circle:nth-child(1) {
    margin-left: -50px;
    margin-top: 60px;
    z-index: -2;
  }

  .circle:nth-child(2) {
    margin-top: -20px;
    z-index: -4;
  }
  .circle:nth-child(3) {
    z-index: -3;
    margin-top: 50px;
    margin-left: 55px;
  }
  .mainPage {
    margin: 0 auto;
  }

  .box {
    min-width: 0;
    border-left: 15px solid #ffffff;
    border-right: 15px solid #ffffff;
    border-top: none;
    margin: 24px auto 0;
  }

  .headerBox {
    max-width: 274px;
    max-height: 276px;
    margin: 24px auto 0 16px;
  }
  .header {
    margin-bottom: 18px;
  }
  .sqdfy,
  .header {
    font-size: 58px;
    line-height: 54px;
  }
  .form {
    width: 260px;
  }
  .formBox {
    padding: 30px 10px;
    margin-top: 9px;

    h3 {
      margin-top: 10px;
      font-size: 18px;
      line-height: 22px;
    }

    p {
      font-size: 14px;
      line-height: 22px;
    }
  }
  .caption {
    font-size: 12px;
    line-height: 18px;
    width: 310px;
    margin-top: 7px;
    margin-bottom: 0;
  }
  .erorrPlace {
    margin-bottom: 22px;
  }
  .errorText {
    margin-bottom: 4px;
  }
}

@media screen and (max-width: 375px) {
  .circle {
    width: 200px;
    height: 200px;
  }

  .circle:nth-child(1) {
    margin-left: -50px;
    margin-top: 60px;
    z-index: -2;
  }

  .circle:nth-child(2) {
    margin-left: 30px;
    z-index: -4;
  }
  .circle:nth-child(3) {
    z-index: -3;
    margin-left: 90px;
  }
}

@media screen and (max-width: 310px) {
  .headerBox {
    margin: 24px 0 0 0;
  }
}
