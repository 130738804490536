.wrapper {
  width: 100vw;
  height: 100vh;
  background-color: #f2eff8;
  padding: 20px;
}
.container {
  background-color: #fff;
  max-width: 580px;
  margin: auto;
  padding: 32px;
  border-radius: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icon {
  font-size: 52px;
}
.header {
  font-size: 24px;
  font-weight: bold;
  color: #1a1426;
}
.message {
  font-size: 16px;
  color: #584a75;
  margin-top: 20px;
}
.buttons {
  margin-top: 30px;
}
.button {
  background-color: #1a1426;
  color: #ffffff;
  text-decoration: none;
  padding: 12px 24px;
  border-radius: 16px;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}
.buttonSecondary {
  color: #1a1426;
  background-color: #f2eff8;
  margin-bottom: 12px;
}

.footer {
  margin-top: 28px;
  font-size: 12px;
  color: #aaa2ba;
}
a {
  color: #3597f1;
}
